import EventManager from './EventManager'
import DataManager from './DataManager'

export default class GalleryModal {

    constructor(options) {
        this.options = $.extend({
            // load more data on modal scroll
            loadOnScroll: false,

            // modal max height
            maxHeight: 500,

            // modal title
            title: 'summernote image gallery',

            // close button text
            close_text: 'Close',

            // save button text
            ok_text: 'Add',

            // select all button text
            back: 'Atras',

            // deselect all button text
            deselectAll_text: 'Deselect all',

            // message error to display when no image is selected
            noImageSelected_msg: 'One image at least must be selected.'
        }, options);

        this.event = new EventManager();

        this.template = this.getModalTemplate();
        this.$modal = $(this.template).hide();

        // class to add to image when selected
        this.select_class = "selected-img";

        this.addStyleToDom();
        this.setOptions();

        this.attachEvents();

        this.lastFolder = [];
    }

    setOptions() {
        this.$modal.find('.modal-body').css('max-height', this.options.maxHeight);
        this.$modal.find('.modal-title').html(this.options.title);
        this.$modal.find('#close').html(this.options.close_text);
        this.$modal.find('#save').html(this.options.ok_text);
        this.$modal.find('#select-all').html(this.options.back);
    }

    // append images to the modal with data object
    addImages(data, page) {

        var page_images = this.createImages(data, page);
        var $images_list = this.$modal.find('.images-list');

        if ($images_list.find('.img-item').length) {
            this.$modal.find('.images-list').append(page_images);
        } else {
            this.$modal.find('.images-list').html(page_images);
        }
    }

    // generate image elements from data object
    createImages(data, page) {
        var attributes = page ? 'class="form-row page-content" data-page"' + page + '"' : '';
        var $content = $('<div ' + attributes + '></div>');
        var _this = this;


        if (data.directory)
            for (var i = 0; i < data.directory.length; i++) {

                var $res = "";
                let arr = data.directory[i].name.split("/");
                if (data.directory[i].type == 'folder') {
                    $res = '<div class="col-6 mb-2"><button class="mybtn btn btn-sm btn-block btn-primary" folder="true" route="' + data.directory[i].route + '">' + arr[arr.length - 1] + '</button></div>'
                } else if (data.directory[i].type == 'file') {
                    let $file = ""
                    if (['jpg', 'jpge', 'png'].includes(data.directory[i].file)) {
                        $file = '<img style="width: 100%;"  src="' + route('file.get',data.directory[i].route) + '" alt="" />'
                    } else if (['mp3'].includes(data.directory[i].file)) {
                        $file = '<audio controls>' +
                            '<source src="' + route('file.get', [data.directory[i].route]) + '" type="audio/mpeg">' +
                            'Your browser does not support the audio element.' +
                            '</audio>'
                    }
                    $res = '<div class="col-6 mb-2">' + $file + '<br><button class="mybtn btn btn-sm btn-block btn-primary" folder="false" file="' + data.directory[i].file + '" route="' + data.directory[i].route + '">' + arr[arr.length - 1] + '</button></div>'
                    //$res = '<div class="col-4 mb-2"><button class="mybtn btn btn-sm btn-block btn-primary" folder="false" file="' + data.directory[i].file + '" route="' + data.directory[i].route + '">' + arr[arr.length - 1] + '</button></div>'
                }
                //var $image = $('<img class="col-md-12 thumbnail" title="'+ data[i].title +'"/>');
                /*
                $res.onload = function() {
                    console.log(ok);
                    $(this).siblings('.loading').hide()
                    $(this).click(function(event) {
                        $(this).toggleClass(_this.select_class);
                    });
                }
    
                $image.attr('src', data[i].src);*/

                var $item = $('<div class="col-md-2 img-item">'
                    + '<i class="fa fa-check"></i>'
                    + '<span class="">'
                    + '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>'
                    + '</span>'
                    + '</div>');

                $item.prepend($res);
                $content.append($res).on('click', 'button', function (event) {
                    $('.mybtn').removeClass('btn-success').addClass('btn-primary');
                    $(this).toggleClass('btn-success');
                    $('.mybtn').removeClass(_this.select_class);
                    $(this).toggleClass(_this.select_class);
                });
            }

        return $content;
    }

    showError(message_text, permanent) {
        var $message = this.$modal.find('.message');

        $message.html('<span class="alert alert-danger">' + message_text + '</span>');

        if (!permanent) {
            setTimeout(function () {
                $message.html('');
            }, 5000);
        }
    }

    showLoading() {
        this.$modal.find('.modal-footer .loading').show();
    }

    hideLoading() {
        this.$modal.find('.modal-footer .loading').hide();
    }

    attachEvents() {
        var _this = this;
        var $modal = this.$modal;
        var $modal_body = $modal.find('.modal-body');

        $modal.find("button#save").click(function (event) {
            var $selected_img = $modal.find('.' + _this.select_class);

            if (!$selected_img.length) {
                _this.showError(_this.options.noImageSelected_msg);
                return;
            } else if ($selected_img[0].getAttribute('folder') == 'true') {
                _this.event.trigger('showMore', [$selected_img[0].getAttribute('route')]);
            } else {
                $modal.modal('hide')

                _this.event.trigger('beforeSave', [_this]);

                $selected_img.each(function (index, el) {
                    let $file = ""
                    if (['jpg', 'jpge', 'png'].includes($selected_img[0].getAttribute('file'))) {
                        $file = '<img src="' + route('file.get',$selected_img[0].getAttribute('route')) + '" alt="" />'
                    } else if (['mp3'].includes($selected_img[0].getAttribute('file'))) {
                        $file = '<audio controls v-if="false">' +
                            '<source src="' + route('file.get', [$selected_img[0].getAttribute('route')]) + '" type="audio/mpeg">' +
                            'Your browser does not support the audio element.' +
                            '</audio>'+
                                '<audio-component :file="route(\'file.get\', \''+$selected_img[0].getAttribute('route')+'\')"></audio-component>'
                    }
                    _this.event.trigger('save', [_this, $file]);

                    $(this).removeClass(_this.select_class);
                });

                _this.event.trigger('afterSave', [this]);
            }
        });

        $modal.on('hidden.bs.modal', function () {
            _this.event.trigger('close')
        })

        $modal.find("button#select-all").click(function (event) {
            //$modal.find('img').addClass(_this.select_class);
            _this.lastFolder.splice(_this.lastFolder.length - 1, 1);
            if (_this.lastFolder.length > 0) {
                _this.event.trigger('showMore', [_this.lastFolder[_this.lastFolder.length - 1]]);
              } else {
                _this.event.trigger('showMore', ['']);
              }
            console.log('aqui', _this.lastFolder)
        });

       

        $modal.delegate(".mybtn", "click", function () {
            var $selected_img = $modal.find('.' + _this.select_class);

            if ($selected_img[0].getAttribute('folder') == 'true') {
                _this.lastFolder.push($selected_img[0].getAttribute('route'));
                $modal.find('#select-all').attr('route', [$selected_img[0].getAttribute('route')]);
                _this.event.trigger('showMore', [$selected_img[0].getAttribute('route')]);
            }
        });

        $modal_body.scroll(function () {
            var $images_list = $modal.find('.images-list');
            var is_near_bottom = $modal_body.scrollTop() + $modal_body.height() >= $images_list.height() - 100;

            if (is_near_bottom) {
                _this.event.trigger('scrollBottom', [_this]);
            }
        });
    }

    open() {
        this.$modal.modal();
    }

    clearContent() {
        // Reset the initial html
        this.$modal.find('.images-list').html('');
    }

    // whether the images container has enough content to show the vertical scroll
    imagesContainerHasScroll() {
        var $images_container = this.$modal.find('.modal-body');
        var $images_list = $images_container.find('.images-list');

        return $images_container.height() != $images_list.height();
    }

    getModalTemplate() {

        var bootsrap_version = parseInt($.fn.modal.Constructor.VERSION);
        var header_content = [
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>',
            '<h4 class="modal-title">[gallery title]</h4>'
        ];

        var modal_html = '' +
            '<div class="modal summernote-resource fade" tabindex="-1" role="dialog">'
            + '<div class="modal-lg modal-dialog ">'
            + '<div class="modal-content">'
            + '<div class="modal-header">'
            + (bootsrap_version == 3 ? header_content.join('') : header_content.reverse().join(''))
            + '</div>'
            + '<div class="modal-body">'
            + '<div class="images-list">'
            + '</div>'
            + '</div>'
            + '<div class="modal-footer">'
            + '<span style="display: none;position: absolute;left: 10px;bottom: 10px;" class="loading" >'
            + '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>'
            + '</span >'
            + '<span style="display: inline-block; margin-right: 50px;">'
            + '<button type="button" id="select-all" class="btn btn-default">[select-all]</button>'
            + '</span >'
            + '<button type="button" id="close" class="btn btn-default" data-dismiss="modal">[Close]</button>'
            + '<button type="button" id="save" class="btn btn-primary">[Add]</button>'
            + '<span class="message" ></span >'
            + '</div>'
            + '</div>'
            + '</div>'
            + '</div>';

        return modal_html;
    }

    addStyleToDom() {
        this.$css = $('<style>'
            + '.img-item{'
            + 'position : relative;'
            + '}'
            + '.img-item .fa-check{'
            + 'position : absolute;'
            + 'top : -10px;'
            + 'right : 5px;'
            + 'font-size: 30px;'
            + 'color: #337AB7;'
            + '}'
            + '.img-item .thumbnail{'
            + 'min-height : 119.66px;'
            + '}'
            + '.img-item .loading{'
            + 'position: absolute;'
            + 'margin: auto;'
            + 'top: -20px;'
            + 'bottom: 0;'
            + 'display: block;'
            + 'left: 0;'
            + 'right: 0;'
            + 'width: 60px;'
            + 'height: 42px;'
            + 'text-align: center;'
            + '}'
            + '.modal.summernote-resource .message{'
            + 'display: block;'
            + 'padding: 30px 0 20px 0;'
            + '}'
            + '.modal.summernote-resource .message:empty{'
            + 'display: block;'
            + 'padding: 0px!important;'
            + '}'
            + '.modal.summernote-resource .modal-body{'
            + 'overflow: scroll;'
            + '}'
            + '.img-item .fa-check{'
            + 'display : none;'
            + '}'
            + '.img-item .' + this.select_class + ' + .fa-check{'
            + 'display : block;'
            + '}'
            + '.' + this.select_class + '{'
            + 'background-color: #5CB85C;'
            + '}'
            + '</style>');
        this.$css.appendTo('body');
    }
}